@import '../../styles/breakpoints';
@import '../../styles/colors1';

.action {
  display: inline-block;
  margin-left: 8px;
}

.isInline {
  position: relative;
  display: inline-block;
}

.content {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  @include breakpoint('sm') {
   width: 75% !important;
  }
}

.fluidContent {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.centerContent {
  text-align: center;
}
