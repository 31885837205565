.text {
  text-align: left;
  font-weight: 600;
  margin-bottom: 1rem;
  align-self: stretch; // MSIE
}

.gateWarning {
  text-align: left;
  font-weight: 600;
}