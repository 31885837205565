
.text {
  margin-top: 0.5em;
  //line-height: 1em;
  //font-size: 1em;
  font-weight: 600;
  margin-bottom: 0.5em;

  align-self: stretch; // MSIE

  a {
    cursor: pointer;
  }
}

.img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.imgContainer {
  position: absolute;
  bottom: 15px;
  width: 80%;
  height: 30%;
}

.fullImg {
  background-position: left top;
  background-size: cover;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  flex-basis: 100%;

  // content
  display: flex;
  justify-content: center;

  //position: absolute;
  //top: 0;
  //left: 0;
  //bottom: 0;
  //right: 0;
}

.cardBody {
  padding: 0;
  position: relative;
  & > * {
    z-index: 0;
  }
}
