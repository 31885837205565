.cardBody {
  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    cursor: pointer;
  }
}

.img {
  width: 125px;
  margin-bottom: 0px;
  margin-top: 0px;
}
