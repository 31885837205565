@mixin typographyBaseFontFamily() {
  font-family: Palanquin, Roboto, Helvetica, Arial, sans-serif;
}

@mixin typographyTitle() {
  font-weight: 600;
  font-size: 2rem;
  line-height: 1;
}

@mixin typographySubtitle() {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1;
}

@mixin typographyHeadline() {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}
