@import '../../styles/colors1';
@import '../../styles/shadows';
@import '../../styles/breakpoints';

.row {
  display: flex;
  justify-content: flex-start;
  background-color: $colorWhite;
  margin: 6px 0;
  cursor: pointer;
  user-select: none;

  flex-wrap: wrap;

  @include breakpoint('sm') {
    flex-wrap: nowrap;
  }

  &:hover {
    @include shadow2dp;
  }

  // icon
  & > *:nth-child(1) {
    display: flex;
    align-items: center;
    padding: 11px;
    color: white !important;
    width: 100%;

    @include breakpoint('sm') {
      width: auto;
    }
  }

  & > *:nth-child(2) {
    flex-basis: 75%;
    padding: 0 6px;
  }

  // amount
  & > *:nth-child(3) {
    flex-basis: 25%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    font-size: 1.4rem;
    font-weight: 600;
    margin: auto;

    @include breakpoint('sm') {
      margin: 0;
    }

    span {
      white-space: nowrap;
    }
  }
}

.details {
  & > tr > td {

    &:nth-child(1) {
      vertical-align: baseline;
    }

    &:nth-child(2), &:nth-child(4) {
      padding-left: 6px;
      font-weight: 600;
    }

    &:nth-child(3) {
      vertical-align: baseline;
      padding-left: 1em;
    }

    // a spacer
    &:nth-child(5) {
      width: 100%;
    }
  }
}

.banner {
  color: white;
  display: flex;
  align-items: center;

  & > :global(.material-icons) {
    padding: 16px;
  }
}

.waitingStatus {
  background-color: $colorBrandYellow;
}

.successStatus {
  background-color: $colorBrandGreen;
}

.failedStatus {
  background-color: $colorRed0;
}

.nowrap {
  white-space: nowrap;
}


.hiddenXs {
  display: none;

  @include breakpoint('sm') {
    display: table-cell;
  }
}

tr.visibleXs {
  display: table-row;

  @include breakpoint('sm') {
    display: none;
  }
}
