@import '../../styles/colors1';
@import '../../styles/typography';
@import '../../styles/transitions';

$_buttonHeightSmall: 28px !default;
$_buttonHeight: 32px !default;
$_buttonHeightLarge: 40px !default;

.button {
  @include typographyBaseFontFamily;
  @include transitionFast(#{'background-color, opacity'});

  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  max-width: 100%;
  height: $_buttonHeight;
  padding: 0 1rem;
  overflow: hidden;
  font-weight: 600;
  font-size: 0.8666rem;
  line-height: $_buttonHeight;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: $colorBlack;
  border: 0;
  border-radius: 9999px;
  background: transparent none;
  cursor: pointer;
  touch-action: manipulation;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $colorGray3;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: $colorGray4;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.buttonIcon {
  position: relative;
  top: 0.384em;
  margin-right: 3px;
  margin-left: 3px;
  vertical-align: middle;

  &:first-child:not(:last-child) {
    left: -6px;
  }

  &:last-child:not(:first-child) {
    right: -6px;
  }
}

.buttonLabel {
  position: relative;
  display: inline-block;
  max-height: 100%;
}

// Priorities
.isButtonPrimary {
  composes: button;
  color: $colorBrandMain;
}

.isButtonSimple {
  composes: button;
  text-transform: none;
  color: $colorBrandMain;
  font-size: 1.25em;
  font-weight: 500;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }

  &:hover {
    text-decoration: underline;
  }
}

.isButtonLarge {
  composes: button;
  height: $_buttonHeightLarge;
  padding: 0 1rem;
  font-size: 1rem;
  line-height: $_buttonHeightLarge;
  letter-spacing: 0;
}

.isButtonLarge > .buttonIcon {
  top: 0.2em;
  margin-right: 2px;
  margin-left: 2px;
}

// Sizes
.isButtonSmall {
  composes: button;
  height: $_buttonHeightSmall;
  font-size: 0.8rem;
  line-height: $_buttonHeightSmall;
  letter-spacing: 0.04em;
}

.isButtonSmall > .buttonIcon {
  top: 0.154em;
  margin-right: 2px;
  margin-left: 2px;
}

.isButtonSmall > .buttonIcon:first-child {
  left: -4px;
}

.isButtonSmall > .buttonIcon:last-child {
  right: -4px;
}

// Priorities and sizes
.isButtonPrimarySmall {
  composes: isButtonPrimary isButtonSmall;
}

.isButtonPrimaryLarge {
  composes: isButtonPrimary isButtonLarge;
}
