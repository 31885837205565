@import '../../../styles/colors1';
@import '../../../styles/zIndexes1';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndexProgressOverlay;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($colorGray2, 0.6);
}

.rootAbsolute {
  composes: root;
  position: absolute;
}
