@import '../../styles/colors1';
@import '../../styles/transitions';
@import '../../styles/typography';

$_fontSize: 1rem;
$_fontWeight: 500;

.root {
  position: relative;
  padding-top: 8px;
  padding-bottom: 20px;
  margin-bottom: 4px;
  font-family: inherit;
  color: $colorBlack;
}

.hasRootNoErrors {
  composes: root;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.rootNoTopSpacing {
  margin-top: -8px;
}

.label {
  @include transitionFast(#{'color, transform'});

  position: absolute;
  top: 24px;
  left: 0;
  font-weight: $_fontWeight;
  font-size: $_fontSize;
  color: $colorGray5;
  cursor: text;
}

.hasLabelNoErrors {
  composes: label;
  top: 19px;
}

.isLabelFloating {
  composes: label;
  color: $colorBlack;
  transform: scale(0.74) translate(0, -22px);
  transform-origin: top left;
  pointer-events: none;
  user-select: none;
  cursor: default;
}

.input {
  @include typographyBaseFontFamily;

  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 16px 0 2px $inputPaddingLeft;
  font-weight: $_fontWeight;
  font-size: $_fontSize;
  line-height: 1.4;
  border: 0;
  border-radius: 0;
  background-color: $colorWhite;
  outline: none;

  &::placeholder {
    color: $colorPLaceholder;
  }

  &.bigText {
    text-align: center;
    font-size: 1.4em;
    padding: 32px 0 20px 0;
  }
}

.rightAligned {
  text-align: right;
}

.underline {
  @include transitionFast(#{'transform'});

  // position: absolute;
  // bottom: 20px;
  position: relative;
  bottom: 2px;
  z-index: 2;
  display: block;
  width: 100%;
  margin: 0;
  border-top: 2px solid $colorBrandMain;
  transform: scaleX(0);
}

.hasUnderlineNoErrors {
  composes: underline;
  // bottom: 8px; // somm: removed due to wrong apperance in filterbar
}

.rootIsThin {
  padding-top: 15px;
  padding-bottom: 0;

  &.noLabel {
    padding: 0;
  }

  & .input {
    height: 30px;
    padding: 8px 2px 2px $inputPaddingLeft;
  }

  & .underline {
    position: relative;
    bottom: 2px;
  }

  & .label {
    top: 21px;
    left: 4px;
  }

  &.framed,
  &.filled {
    .isLabelFloating {
      transform: scale(0.74) translate(0, -32px);
    }
  }
}

// Tested only in combination with isFilled
.rootIsExtraThin {
  padding-top: 15px;
  padding-bottom: 0;
  margin-bottom: 0;

  &.noLabel {
    padding: 0;
  }

  & .input {
    height: 21px;
    padding: 2px 2px 2px $inputPaddingLeft;
  }

  & .underline {
    position: relative;
    bottom: 2px;
  }

  & .label {
    top: 21px;
    left: 4px;
  }

  &.framed,
  &.filled {
    .isLabelFloating {
      transform: scale(0.74) translate(0, -32px);
    }
  }
}

.helpText {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  font-weight: 500;
  font-size: 0.74rem;
  color: $colorGray5;
}

.errorText {
  composes: helpText;
  color: $colorRed0;
}

.input:focus + .underline {
  transform: scaleX(1);
}

.framed .input {
  padding: 6px 2px 4px $inputPaddingLeft;
  border: 2px solid $colorGray7;
  border-radius: 5px;
}

.filled .input {
  border: none;

  &:not([disabled]) {
    background-color: $colorYellow1;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 6px;

  &:not(.noUnderline) {
    border-bottom: 1px solid $colorGray4;
  }
}

.inputIcon {
  margin: auto 2px 2px;
}
