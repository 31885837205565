@import '../../styles/breakpoints';

@mixin _makeRow($_gutterCompensation) {
  .row {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-right: $_gutterCompensation;
    margin-left: $_gutterCompensation;
  }

  .isRowReversed {
    composes: row;
    flex-direction: row-reverse;
  }
}

@mixin _columnBase($_halfGutterWidth) {
  box-sizing: border-box;
  padding-right: $_halfGutterWidth;
  padding-left: $_halfGutterWidth;
}

@mixin _column($_span, $_columns) {
  flex-basis: percentage($_span / $_columns);
  max-width: percentage($_span / $_columns);
}

@mixin _columnAutoWidth() {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

@mixin _columnOffset($_span, $_columns) {
  @if $_span == 0 {
    margin-left: 0;
  }

  @else {
    margin-left: percentage($_span / $_columns);
  }
}

@mixin _align($_direction) {
  @if $_direction == 'start' {
    justify-content: flex-start;
    text-align: start;
  }

  @else if $_direction == 'center' {
    justify-content: center;
    text-align: center;
  }

  @else if $_direction == 'end' {
    justify-content: flex-end;
    text-align: end;
  }

  @else if $_direction == 'top' {
    align-items: flex-start;
  }

  @else if $_direction == 'middle' {
    align-items: center;
  }

  @else if $_direction == 'bottom' {
    align-items: flex-end;
  }
}

@mixin _space($_type) {
  @if $_type == 'around' {
    justify-content: space-around;
  }

  @else if $_type == 'between' {
    justify-content: space-between;
  }
}

@mixin _makeColumns($_breakpoint, $_columns, $_halfGutterWidth, $_alignments, $_spacings) {
  .col_#{$_breakpoint} {
    flex: 0 0 auto;
    @include _columnBase($_halfGutterWidth);
    @include _columnAutoWidth;
  }

  @each $_direction in $_alignments {
    .#{$_direction}_#{$_breakpoint} {
      @include _align($_direction);
    }
  }

  @each $_spacing in $_spacings {
    .#{$_spacing}_#{$_breakpoint} {
      @include _space($_spacing);
    }
  }

  @for $_i from 1 through $_columns {
    .col_#{$_breakpoint}_#{$_i} {
      flex: 0 0 auto;
      @include _columnBase($_halfGutterWidth);
      @include _column($_i, $_columns);
    }
  }

  @for $_i from 0 through ($_columns - 1) {
    .col_#{$_breakpoint}_offset_#{$_i} {
      @include _columnBase($_halfGutterWidth);
      @include _columnOffset($_i, $_columns);
    }
  }
}

@mixin _makeBreakpoint($_breakpoint, $_columns, $_halfGutterWidth, $_alignments, $_spacings) {
  @if $_breakpoint == 'xs' {
    @include _makeColumns($_breakpoint, $_columns, $_halfGutterWidth, $_alignments, $_spacings);
  }

  @else {
    @include breakpoint($_breakpoint) {
      @include _makeColumns($_breakpoint, $_columns, $_halfGutterWidth, $_alignments, $_spacings);
    }
  }
}

@mixin _makeGrid($_columns, $_halfGutterWidth, $_gutterCompensation, $_alignments, $_spacings) {
  @include _makeRow($_gutterCompensation);

  @each $_breakpoint, $_value in $breakpoints {
    @include _makeBreakpoint($_breakpoint, $_columns, $_halfGutterWidth, $_alignments, $_spacings);
  }
}
