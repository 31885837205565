.widgetEnter {
  opacity: 0.01;
}

.widgetEnterActive {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.widgetLeave {
  opacity: 1;
}

.widgetLeaveActive {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}