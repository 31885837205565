@import '../../styles/colors1';
@import '../../styles/shadows';
@import '../../styles/transitions';
@import '../../styles/typography';

$_fontSize: 1rem;
$_fontWeight: 500;

.root {
  @include typographyBaseFontFamily;

  position: relative;
  padding-top: 8px;
  padding-bottom: 20px;
  margin-bottom: 4px;
  color: $colorBlack;
  border: 0;

  &::after {
    content: '';
    position: absolute;
    top: 35px;
    right: 8px;
    width: 0;
    height: 0;
    padding: 0;
    border-top: 6px solid $colorBlack;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    pointer-events: none;
  }
}

.hasRootNoErrors {
  composes: root;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.isLabelFloating {
  position: absolute;
  top: 7px;
  left: 0;
  font-weight: $_fontWeight;
  font-size: 0.74em;
  color: $colorBlack;
}

.isLabelFloatingStandalone {
  composes: isLabelFloating;
  left: 8px;
}

.select {
  @include typographyBaseFontFamily;

  box-sizing: border-box;
  width: 100%;
  height: 51px;
  padding: 20px 24px 7px $inputPaddingLeft;
  font-weight: $_fontWeight;
  font-size: $_fontSize;
  line-height: 1.4;
  vertical-align: middle;
  border: 0;
  border-bottom: 1px solid $colorGray4;
  border-radius: 0;
  background-color: $colorWhite;
  color: $colorBrandMain;
  appearance: none;
  outline: none;
}

.select::-ms-expand {
  display: none;
}

.isSelectStandalone {
  composes: select;
  padding-left: 8px;
}

.option {
  @include typographyBaseFontFamily;
  @include shadow2dp;

  padding: 0;
  font-weight: $_fontWeight;
  font-size: $_fontSize;
  background-color: $colorWhite;
  cursor: pointer;
}

.errorText {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 500;
  font-size: 0.74rem;
  color: $colorRed0;
}

.rootIsThin {
  padding-bottom: 0px;

  &.rootNoLabel {
    padding: 0;
    margin: 0;
  }

  & .isLabelFloating {
    top: -2px;
    left: 4px;
  }

  & .select {
    height: 40px;
    padding: 11px 24px 8px $inputPaddingLeft;
  }

  & .errorText {
    position: relative;
    bottom: 0;
    left: 0;
  }

  &::after {
    top: 20px;
    right: 0;
  }
}

select.framed {
  border: 2px solid $colorGray7;
  border-radius: 5px;
}

.rightAligned {
  select {
    text-align-last: right;
  }
}
