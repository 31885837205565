@import '../../styles/breakpoints';
@import '../../styles/colors1';
@import '../../styles/shadows';
@import '../../styles/variables1';

.root {
  // @include shadow2dp;

  flex-shrink: 0;
  background-color: $colorWhite;
  z-index: 1; // put above my places map (z-index: 0)
}

.hasRootTabs {
  composes: root;
  // padding-bottom: 8px;
  padding-bottom: $_tabsHeight;

  // @include breakpoint('md') {
  //   padding-bottom: 4px;
  // }
}
