@import '../../styles/breakpoints';
@import '../../styles/colors1';
@import '../../styles/shadows';
@import '../../styles/transitions';
@import '../../styles/zIndexes1';
@import '../../styles/typography';

.label {
  display: block;
  font-size: 18px;
  color: $colorBrandMain;
}

.subLabel {
  display: block;
  color: $colorBrandMain;
}

.disabledButton .label {
  color: $colorGray5;
}

.disabledButton .subLabel {
  color: $colorGray5;
}


.button {
  @include typographyBaseFontFamily;

  position: relative;
  flex: 1;
  box-sizing: border-box;
  padding: 4px 8px 0;
  font-weight: 600;
  font-size: 0.733rem;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: $colorGray5;
  border: 0;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  background-color: $colorWhite;
  cursor: pointer;

  text-overflow: ellipsis;
  overflow: hidden;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $colorBrandMain;
    color: $colorWhite;

    .label, .subLabel {
      color: $colorWhite;
    }
  }

  &:focus {
    // background-color: $colorGray1;
    outline: none;
  }
}

.disabledButton {
  composes: button;

  background-color: $colorGray3;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $colorGray3;
    color: $colorGray5;

    .label, .subLabel {
      color: $colorGray5;
    }
  }
}
