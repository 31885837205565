@import '../../../../styles/colors1';

.cardBody {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative; // due to Overlay
}

.img {
  width: 125px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.amount {
  font-weight: 600;
  margin-left: 0.25em;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  width: 100%;
  margin-top: 15px;

  ul {
    list-style: none;
    padding-left: 0;
  }

  h3 {
    margin-top: 0px;
  }
}

.paymentTypes {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;

  & > button:not(:nth-child(1)) {
    margin-left: 5px;
  }
}

.paymentTypeLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feeText {
  text-transform: none;
}

.banner {
  background-color: $colorYellow1;
  margin-bottom: 0;
  padding: 10px;
}