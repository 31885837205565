@import '../../styles/colors1';

.version {
  position: absolute;
  right: 5px;
  z-index: -1;
  // display: flex;
  // // flex-grow: 1;
  // flex-direction: column;
  // align-self: flex-end;
  // justify-content: flex-end;
  padding: 5px;
  color: $colorGray5;
}