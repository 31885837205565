@import '../../styles/colors1';

.root {
  margin: 0px 0px -1px;
  padding: 20px 16px 16px;
  color: $colorBlack;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  background-color: $colorGray3;
  border-radius: 2px 2px 0px 0px;

  display: flex;
  align-items: center;
}

.rootWarning {
  background-color: $colorRed0;
  color: $colorWhite;
}

.rootSuccess {
  background-color: $colorBrandGreen;
  color: $colorWhite;
}

:global(.MuiIconButton-root).closeButton {
  position: absolute;
  right: 3px;
  top: 3px;

  // button de-style
  padding: 0;
  border: 0;
  background: none;
  // color: $colorBlack;
  cursor: pointer;

  span {
    font-size: 25px !important; // mui icon size
  }
}
