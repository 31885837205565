.detailTable {

  td {
    padding-bottom: 0.5em;
  }

  tr {
    font-weight: 500;
  }

  tr.header {
    font-weight: 600;
  }

  th:first-child {
    text-align: left;
    font-weight: 500;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 100%;

  &.centered {
    align-items: center;
  }

  &>.centered {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }

  .paragraph {
    margin-bottom: 24px;
  }

  p.noMargin {
    margin-bottom: 0;
  }

  h4 {
    margin: 0;
    margin-top: 0.5em;
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    height: 32;
  }
}

.logo {
  object-fit: contain;
  max-width: 50%;
  height: auto;
  margin: 1em 0;
}

.logoGoPay {
  object-fit: contain;
  max-width: 200px;
  margin-left: 10px;
  height: auto;
}

.cardText {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  font-weight: 600;

  >*:not(:first-child) {
    margin-left: 1em;
  }
}

.cardSelect {
  display: flex;
  justify-content: center;
}

.anotherCards {
  margin-bottom: 1em;

  >div {
    margin-bottom: 1em;
    display: flex;
    min-width: 200px;
    justify-content: space-between;
  }

}