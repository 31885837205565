@import '../../../../styles/colors1';

.fullImgNoTitle {
  background-position: top;
  background-size: cover;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: $cardBorderRadius - 2;
}

.fullImg {
  composes: fullImgNoTitle;
  border-radius: 0;
}

.cardBody {
  position: relative;
  justify-content: flex-end;
  & > * {
    z-index: 0;
  }
}

.bottomRoot {
  background: rgba($colorWhite, .7);
  align-self: stretch;
  text-align: left;
  padding: .5em 1em;

  & > h3 {
    margin-top: 0;
    margin-bottom: 0.4em;
  }
  & > p {
    margin: 0;
  }
}

.category {
  position: absolute;
  top: 20px;
  right: 0;
  background: $colorWhite;
  color: $colorBrandMain;
  padding: 2px 10px;
  font-size: 0.9em;
  font-weight: 700;
}
