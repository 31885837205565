.topBanner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;

  h3 {
    margin: 0;
  }
}

.bannerIcon {
  text-align: center;
  margin-top: 6px;
  margin-right: 10px;
}