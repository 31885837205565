@import '../../../../styles/colors1';

.text {
  margin-top: 20px;
  text-shadow: 0px 0px 3px #000000;
  line-height: 1.5em;
  font-size: 1.75em;
  font-weight: 600;
  margin-bottom: 1em;
  align-self: stretch; // MSIE
}

.smalltext {
  // line-height: 1.25em;
  font-size: 1.25em;
  font-weight: 500;
  text-shadow: 0px 0px 3px #000000;
  margin-bottom: 1em;
  align-self: stretch; // MSIE

  a {
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      color: lightgray;
    }
  }
}

.whiteText {
  color: white;

  a {
    color: white;
  }
}

.img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.imgContainer {
  position: absolute;
  bottom: 15px;
  width: 80%;
  height: 30%;
}

.fullImgNoTitle {
  background-position: top;
  background-size: cover;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: $cardBorderRadius - 2;
}

.fullImg {
  composes: fullImgNoTitle;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cardBody {
  position: relative;
  & > * {
    z-index: 0;
  }
}
