.text {
  line-height: 1.5em;
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 1em;
  margin-top: 1em;
  align-self: stretch; // MSIE
}

.img {
  object-fit: contain;
  max-width: 80%;
  max-height: 30%;
}

.imgAutoSize {
  object-fit: contain;
  max-width: 80%;
  height: auto;
}

.bottomSpacer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1.5em;
}

.smallMargin {
  margin-bottom: 0.5em;
}