@import '../../styles/breakpoints';
@import '../../styles/colors1';

.empty {
  position: relative;
  width: 100%;
  padding: 32px 0;
  // margin-bottom: 24px;
  overflow: hidden;
  font-size: 28px;
  text-align: center;
  color: $colorBrown0;
  border-radius: 2px;
  background-color: rgba($colorWhite, 0.8);
}
//
//.emptyTopBottom,
//.emptyLeftRight {
//  position: absolute;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  z-index: 1;
//}
//
//.emptyLeftRight::before {
//  content: '';
//  position: absolute;
//  top: 0;
//  right: 2px;
//  bottom: 0;
//  left: 0;
//  width: 2px;
//  height: 100%;
//  background-image: linear-gradient(to top, $colorWhite 60%, transparent 0%);
//  background-position: bottom;
//  background-size: 2px 20px;
//  background-repeat: repeat-y;
//}
//
//.emptyLeftRight::after {
//  content: '';
//  position: absolute;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  width: 2px;
//  height: 100%;
//  background-image: linear-gradient(to bottom, $colorWhite 60%, transparent 0%);
//  background-position: bottom;
//  background-size: 2px 20px;
//  background-repeat: repeat-y;
//}
//
//.emptyTopBottom::before {
//  content: '';
//  position: absolute;
//  top: 0;
//  right: 0;
//  left: 0;
//  width: 100%;
//  height: 2px;
//  background-image: linear-gradient(to right, $colorWhite 60%, transparent 0%);
//  background-position: top;
//  background-size: 20px 2px;
//  background-repeat: repeat-x;
//}
//
//.emptyTopBottom::after {
//  content: '';
//  position: absolute;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  width: 100%;
//  height: 2px;
//  background-image: linear-gradient(to left, $colorWhite 60%, transparent 0%);
//  background-position: bottom;
//  background-size: 20px 2px;
//  background-repeat: repeat-x;
//}

.emptyContent {
  position: relative;
  z-index: 2;
  max-width: 440px;
  padding: 16px;
  margin: 0 auto;
}
