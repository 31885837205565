.cardRoot {
  background-color: transparent;
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin-left: 0.5em !important;
  }

  select {
    border-radius: 4px;
  }
}

.cardBody {
  padding: 0 6px 0 6px;
  position: relative;
}
