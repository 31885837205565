@import '../../styles/colors1';

.root {
  text-align: left;

  // &>div>label:global(.MuiFormControlLabel-root) {
  //   align-items: start !important;
  // }
}

.hasRootLink {
  composes: root;
  display: flex;
}

.inputContainer {
  padding-right: 0.5ex;
  white-space: nowrap;
}

.children {
  cursor: pointer;
}

.helpText {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  font-weight: 500;
  font-size: 0.74rem;
  color: $colorGray5;
}

.errorText {
  composes: helpText;
  color: $colorRed0;
}
