$zIndexMain: 1 !default;
$zIndexMapEditButton: 2 !default;
$zIndexGeosuggestSuggestions: 3 !default;
$zindexNavigationBar: 3 !default;
$zIndexFlashMessageList: 1301 !default; // above modal backdrop
$zIndexHeader: 5 !default;
$zIndexNavMenu: 5 !default;
$zIndexNavMenuShadow: 6 !default;
$zIndexUserMenu: 7 !default;
$zIndexProgressOverlay: 1000 !default;
