.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 80%;
}

.desc {
  margin-top: 1rem;
  border-top: 1px solid #b2b2b2;
  padding: 1rem;
  text-align: justify;
}

.textRight {
  text-align: center;
}

.secondary {
  margin: 0;
  color: orange;
}

.product {
  margin-top: 6px;
  display: flex;
}

.bottomSpacer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1.5em;
}
