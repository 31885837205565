// NOTICE
// Please keep synced with `./colors.js` which is used to configure Material UI components.
// Colors are named after prevailing color and number starting from 0 - the lightest.
// If transparent, add suffix 'Transparent'.

$colorWhite: #fff !default;
$colorBlack: #4f5d5b !default;

// Primary palette
$colorBrandMain: #f59100 !default;
$colorBrandGreen: #9cc435 !default;

// Secondary palette
$colorBrandYellow: #f5b510 !default;
$colorBrandBlue: #5eadd7 !default;
$colorBrandOrange: #f08100 !default;
$colorBrandGreenDark: #5c710b !default;
$colorRed0: #e83c49 !default;
$colorRed1: #c32c01 !default;


// Grays
$colorGray1: #f5f5f5 !default;
$colorGray2: #eee !default;
$colorGray3: #e5e5e5 !default;
$colorGray4: #b2b2b2 !default;
$colorGray5: #86939e !default;
$colorGray6: #828282 !default;
$colorGray7: #61666b !default;
$colorGrayBorder: #7b8583 !default;
$colorPLaceholder: #a1a1aa !default;

// Brown
$colorBrown0: #837e70 !default;

$colorGreen0: #e6ffc3 !default;

$colorYellow1: #fff2cd !default;

$cardBorderRadius: 6px;
$inputPaddingLeft: 0;
