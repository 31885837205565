@import '../../styles/colors1';
@import '../../styles/shadows';
@import '../../styles/typography';
@import '../../styles/transitions';
@import '../../styles/breakpoints';

$_buttonHeight: 32px !default;
$_buttonHeightLarge: 40px !default;
$_buttonHeightExtraLarge: 56px !default;

.button {
  @include typographyBaseFontFamily;
  @include shadow2dp;
  @include transitionFast(#{'background-color, box-shadow'});

  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  max-width: 100%;
  padding: 0 1rem;
  overflow: hidden;
  font-weight: 700;
  font-size: 0.9333rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: $colorBrandMain;
  border: 2px solid $colorBrandMain;
  border-radius: 9999px;
  background: $colorWhite none;
  cursor: pointer;
  letter-spacing: 0.0333em;
  touch-action: manipulation;
  height: $_buttonHeight;
  line-height: $_buttonHeight;

  // &:not(.multiLine) {

  // }

  &.multiLine {
    height: unset;
    line-height: unset;
    white-space: pre-line; // break-line at \A character in label
    padding-top: 12px;
    padding-bottom: 8px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $colorWhite;
    background-color: $colorBrandMain;
  }

  &:focus {
    outline: none;
  }

  &:active {
    @include shadow6dp;

    background-color: $colorWhite;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.buttonIcon {
  position: relative;
  top: 0.1333em;
  margin-right: 3px;
  margin-left: 3px;
  vertical-align: middle;

  &:not(.noLabeled) {
    &:first-child {
      left: -6px;
    }

    &:last-child {
      right: -6px;
    }
  }
}

.buttonLabel {
  position: relative;
  display: inline-block;
  top: -0.15em;
  max-height: 100%;
  max-width: 100%;

  &.mdLabel {
    display: none;
    @include breakpoint('md') {
      display: inline-block;
    }
  }

  &.xsLabel {
    display: inline-block;
    @include breakpoint('md') {
      display: none;
    }
  }
}

// Priorities
.isButtonPrimary {
  composes: button;
  color: $colorWhite;
  background-color: $colorBrandMain;

  &:hover,
  &:focus,
  &:active {
    // background-color: lighten($colorBrandMain, 8%);
    color: $colorBrandMain;
    background: $colorWhite none;
  }
}

.isButtonImportant {
  composes: button;
  color: $colorBrandMain;
  border-color: $colorBrandMain;
}

.isButtonSuccess {
  composes: button;
  color: $colorBrandGreen;
  border-color: $colorBrandGreen;
}

// Sizes
.isButtonLarge {
  composes: button;
  height: $_buttonHeightLarge;
  padding: 0 1rem;
  font-size: 1rem;
  line-height: $_buttonHeightLarge;
  letter-spacing: 0;
}

.isButtonLarge > .buttonLabel {
  top: -0.2em;
}

.isButtonExtraLarge {
  composes: button;
  height: $_buttonHeightExtraLarge;
  padding: 0 20px;
  font-size: 1.125rem;
  line-height: $_buttonHeightExtraLarge;
  letter-spacing: 0;
}

.isButtonExtraLarge > .buttonIcon {
  top: 0.2em;
  margin-right: 4px;
  margin-left: 4px;
}

.isButtonExtraLarge > .buttonIcon:first-child {
  left: -8px;
}

.isButtonExtraLarge > .buttonIcon:last-child {
  right: -8px;
}

.isButtonExtraLarge > .buttonLabel {
  top: -0.2em;
}

// Block buttons
.isButtonBlock {
  composes: button;
  display: block;
  width: 100%;
}

// Block and priorities
.isButtonPrimaryBlock {
  composes: isButtonPrimary isButtonBlock;
}

.isButtonImportantBlock {
  composes: isButtonImportant isButtonBlock;
}

.isButtonSuccessBlock {
  composes: isButtonSuccess isButtonBlock;
}

// Block and sizes
.isButtonLargeBlock {
  composes: isButtonLarge isButtonBlock;
}

.isButtonExtraLargeBlock {
  composes: isButtonExtraLarge isButtonBlock;
}

// Priorities and sizes
.isButtonPrimaryLarge {
  composes: isButtonPrimary isButtonLarge;
}

.isButtonPrimaryExtraLarge {
  composes: isButtonPrimary isButtonExtraLarge;
}

.isButtonImportantLarge {
  composes: isButtonImportant isButtonLarge;
}

.isButtonImportantExtraLarge {
  composes: isButtonImportant isButtonExtraLarge;
}

.isButtonSuccessLarge {
  composes: isButtonSuccess isButtonLarge;
}

.isButtonSuccessExtraLarge {
  composes: isButtonSuccess isButtonExtraLarge;
}

// Block, priorities and sizes
.isButtonPrimaryLargeBlock {
  composes: isButtonPrimary isButtonLarge isButtonBlock;
}

.isButtonPrimaryExtraLargeBlock {
  composes: isButtonPrimary isButtonExtraLarge isButtonBlock;
}

.isButtonImportantLargeBlock {
  composes: isButtonImportant isButtonLarge isButtonBlock;
}

.isButtonImportantExtraLargeBlock {
  composes: isButtonImportant isButtonExtraLarge isButtonBlock;
}

.isButtonSuccessLargeBlock {
  composes: isButtonSuccess isButtonLarge isButtonBlock;
}

.isButtonSuccessExtraLargeBlock {
  composes: isButtonSuccess isButtonExtraLarge isButtonBlock;
}
