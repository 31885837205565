/* stylelint-disable declaration-block-no-redundant-longhand-properties */

$_durationMultiplier: 1;

@mixin transitionBase($_property, $_delay: 0ms) {
  transition-property: $_property;
  transition-duration: $_durationMultiplier * 500ms;
  transition-timing-function: cubic-bezier(0.2, 1, 0.7, 1);
  transition-delay: $_delay;
}

@mixin transitionSlow($_property, $_delay: 0ms) {
  transition-property: $_property;
  transition-duration: $_durationMultiplier * 850ms;
  transition-timing-function: cubic-bezier(0.2, 1, 0.7, 1);
  transition-delay: $_delay;
}

@mixin transitionFast($_property, $_delay: 0ms) {
  transition-property: $_property;
  transition-duration: $_durationMultiplier * 250ms;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: $_delay;
}
