@import '../../styles/breakpoints';
@import '../../styles/colors1';
@import '../../styles/typography';

.root {
  padding: 0;
  margin: 0 0 24px;
  border: 0;
}

.rootNoMargin {
  composes: root;
  margin: 0;
}

.legend {
  @include typographyBaseFontFamily;

  padding-top: 8px;
  padding-bottom: 16px;
  margin: 0;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1;
  color: $colorBlack;

  @include breakpoint('md') {
    padding-top: 16px;
    padding-bottom: 24px;
    font-weight: 500;
    font-size: 1.4rem;
  }
}

.isLegendSecondary {
  composes: legend;

  @include breakpoint('md') {
    font-weight: 700;
    font-size: 1.2rem;
  }
}
