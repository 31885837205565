@import '../../styles/zIndexes1';
@import '../../styles/breakpoints';
@import '../../styles/colors1';

.root {
  position: relative;
  z-index: $zIndexMain;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .root > div {
    min-width: calc(100vw - 16px);

    @include breakpoint('xs') {
      min-width: calc(100vw - 32px);
    }

    @include breakpoint('md') {
      min-width: calc(100vw - 48px);
    }

    @media only screen and (min-width: 100rem) {
      min-width: 100rem;
    }
  }
}
