$breakpoints: (
  xxs: 20em,
  xs: 30em,
  sm: 48em,
  md: 64em,
  lg: 75em,
  xl: 110em,
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Please make sure `#{$breakpoint}` is defined in `$breakpoints` map.";
  }
}

@mixin maxBreakpointOrientation($breakpoint, $orientation) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (max-width: #{map-get($breakpoints, $breakpoint) - 0.25em}) and (orientation: $orientation) {
      @content;
    }
  }

  @else {
    @warn "Please make sure `#{$breakpoint}` is defined in `$breakpoints` map.";
  }
}
