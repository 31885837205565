@import '../../styles/colors1';

.viewField {
  font-weight: 600;
  font-size: 1rem;

  &.lgValue {
    font-size: 1.25rem;
  }

  span:not(:global(.material-icons)) {
    font-size: 0.8rem;
    font-weight: 300;
    color: $colorGray7;
  }
}