@import './mixins';

$_columns: 12 !default;
$_gutterWidth: 1rem !default;
$_halfGutterWidth: calc((#{$_gutterWidth} * 0.5)) !default;
$_gutterCompensation: calc((#{$_gutterWidth} * 0.5) * -1) !default;
$_alignments: 'start', 'center', 'end', 'top', 'middle', 'bottom' !default;
$_spacings: 'around', 'between' !default;

@include _makeGrid($_columns, $_halfGutterWidth, $_gutterCompensation, $_alignments, $_spacings);

.col_justify_flex-end {
  display: flex;
  justify-content: flex-end;
}

.col_justify_center {
  display: flex;
  justify-content: center;
}

.col_flex {
  display: flex;
}