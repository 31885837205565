.root {
  padding: 0;
  margin: 24px 0;
  list-style: none;
}

.isRootCompact {
  composes: root;
  margin: 0;
}

.item {
  padding-left: 0;
}

.item + .item {
  margin-top: 12px;
}
