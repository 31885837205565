@import '../../styles/breakpoints';

.image {
  display: block;
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.columns {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}

.columnLeft {
  display: none;

  @include breakpoint('xs') {
    display: block;
    flex-grow: 1;
  }
}

.columnRight {
  flex: 2 0 auto;
  max-width: 100%;
  text-align: center;

  @include breakpoint('xs') {
    padding-left: 8px;
    text-align: left;
  }
}

.columnsItem + .columnsItem {
  margin-top: 12px;
}

.form {
  padding: 16px;

  @include breakpoint('xs') {
    padding: 32px 48px;
  }
}
