.root {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.rootApp {
  composes: root;
  user-select: none; // set default select behaviour for mobile app
}

.withBanner {
  height: calc(100% - 28px);
}