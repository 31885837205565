@import '../../styles/breakpoints';
@import '../../styles/colors1';

.root {
  box-sizing: border-box;
  width: 100%;
  max-width: 100rem;
  padding: 24px 8px;
  margin-right: auto;
  margin-left: auto;
  z-index: 1; // due to Subheader z-index (firm/user tabs)

  @include breakpoint('xs') {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include breakpoint('md') {
    padding: 32px 24px;
  }
}

.hasRootTabs {
  composes: root;
  padding-bottom: 0;
}
