.cardBody {
  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    cursor: pointer;
  }
}

.contentFlex {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  p {
    margin-bottom: 0;
  }
}

.img {
  width: 125px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.termsModal {
  ul {
    list-style-type: upper-alpha;
  }
}