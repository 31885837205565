.logo {
  height: 5em;
  margin-bottom: 20px;
}

.text {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 1.5em;
  align-self: stretch; // MSIE
}

.bottomSpacer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1.5em;
}