@import '../../styles/breakpoints';
@import '../../styles/colors1';
@import '../../styles/shadows';
@import '../../styles/typography';

.root {
  @include shadow2dp;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  height: 296px;
  margin-bottom: 16px;
  border-radius: $cardBorderRadius;
  background-color: $colorWhite;
  border: 1px solid $colorGrayBorder;

  @include breakpoint('md') {
    margin-bottom: 24px;
  }
}

.isRootFluid {
  composes: root;
  height: calc(100% - 16px);

  @include breakpoint('md') {
    height: calc(100% - 24px);
  }
}

.isRootFormBase {
  composes: root;
  width: calc(100% - 2 * 8px);
  max-width: 448px;
  height: auto;
  margin: 16px auto;

  @include breakpoint('xs') {
    width: calc(100% - 2 * 16px);
  }

  @include breakpoint('md') {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.isRootForm {
  composes: isRootFormBase;
  text-align: center;
}

.isRootFormWider {
  composes: isRootFormBase;
  max-width: 548px;
  text-align: center;
}

.header {
  flex-shrink: 0;
  box-sizing: border-box;
  height: 56px;
  padding: 12px 16px;
  color: $colorBlack;
  border-top-left-radius: $cardBorderRadius;
  border-top-right-radius: $cardBorderRadius;
  background-color: $colorGray3;
  border-bottom: 1px solid $colorGrayBorder;
  position: relative; // due to .headerClose
}

.isHeaderImportant {
  composes: header;
  background-color: $colorRed0;
}

.isHeaderSuccess {
  composes: header;
  background-color: $colorBrandGreen;
}

.headerThinPadding {
  padding-top: 4px;
  padding-bottom: 4px;
}

.headerTitle {
  @include typographyHeadline;

  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  line-height: 1.333;
  white-space: nowrap;
  text-overflow: ellipsis;

  // alignment for MyCard title
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:global(.MuiIconButton-root).headerClose {
  position: absolute;
  top: 3px;
  right: 3px;

  // button de-style
  padding: 0;
  border: 0;
  background: none;
  color: $colorBlack;
  cursor: pointer;
}

.body {
  flex: 1 0 auto;
  padding: 16px;
}

.isBodyCentered {
  composes: body;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.isBodyVCentered {
  composes: body;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.isBodyFill {
  composes: body;
  display: flex;
  padding: 0;
}

.isBodySpaceBetween {
  composes: body;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 53px + 1px;
  padding: 0 8px;
  overflow: hidden;
  border-top: 1px solid $colorGrayBorder;
  border-bottom-right-radius: $cardBorderRadius;
  border-bottom-left-radius: $cardBorderRadius;
  background-color: $colorWhite;
}
