@import '../../styles/breakpoints';

.root {
  padding-right: 8px;
  padding-left: 8px;

  @include breakpoint('md') {
    padding-right: 16px;
    padding-left: 16px;
  }
}
