@import '../../styles/breakpoints';
@import '../../styles/colors1';
@import '../../styles/shadows';
@import '../../styles/typography';

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: 295px; // two products, both green stripe
  min-height: 195px; // two products, no limit green stripe
}

.panelContainer {
  margin-top: 7px;

  &:first-child {
    margin-top: 0px;
  }
}

.panel {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $colorGray2;
  padding: 7px 0px;
  font-size: 17px;
  font-weight: 600;

  &.alignTop {
    align-items: flex-start;
  }
}

.cardDetails {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 5px;

  :global(.material-icons) {
    color: $colorGray7 !important;
  }
}

.cardStatus {
  display: flex;
  align-items: center;
}

.amountContainer {
  &:not(:first-of-type) {
    flex-basis: 30%;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.productLabel {
  font-size: 14px;
}

.amount {
  font-size: 20px;
  white-space: nowrap; // mainly due to timelapse icon wrapping
}

.panelLimitInfo {
  background-color: $colorGreen0;
  color: $colorBrandGreenDark;
  display: flex;
  padding: 6px;
}

.panelClickable {
  cursor: pointer;
  user-select: none;
}

.cardContent .cardNoProducts {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 180px;

  :global(.material-icons) {
    font-size: 100px !important;
    color: $colorGray7 !important;
  }
}