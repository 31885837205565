.logo {
  height: 5em;
  margin-bottom: 20px;
}

.text {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 1.5em;
  align-self: stretch; // MSIE
}
